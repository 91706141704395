import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PatientAppointmentItem, SessionPayload } from '../../session-scheduler/session-scheduler.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RppPatientService {
  private isTimelineView = new BehaviorSubject<boolean>(false);
  $timelineView = this.isTimelineView.asObservable();

  rolename = environment.ROLES.RPP_COUNSELOR;
  constructor(private http: HttpClient) { }

  timelineView(isTimeline: boolean) {
    this.isTimelineView.next(isTimeline);
  }

  getPatientRPPGridDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientRppGrid`, payload)
  }

  getPatientRPPMappingDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientRppAssignmentGrid`, payload)
  }

  addPatientRPPAssignmentDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/addRppAssignment`, payload)
  }

  updatPatientRPPAssignmentDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/updateRppAssignment`, payload)
  }

  findbyMobileNumber(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/findRppAssignmentByMobile`, payload)
  }
  getPatientRPPAssignmentDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getRppAssignment`, payload)
  }
  getPatientPreviousAssignedDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientPreviousAssigned`, payload)
  }
  getConsullerlist() {
    return this.http.get(`${environment.admin_prefix_url}/user/getUsersByRole?role_name=${this.rolename}`)
  }

  getUsersByRole(rolename) {
    return this.http.get(`${environment.admin_prefix_url}/user/getUsersByRole?role_name=${rolename}`)
  }

  getServicelist(package_id) {
    return this.http.get(`${environment.admin_prefix_url}/servicemaster/getServicesByPackage/${package_id}`)
  }

  getServicesByRppId(rpp_id) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/rpp-assigned-services/${rpp_id}`)
  }



  responceServicelist(package_id) {
    return this.http.get(`${environment.admin_prefix_url}/servicemaster/${package_id}`)
  }
  //Induction Report
  getInductionReport(payload: { page_size: number; current_page: number; start_date: string; end_date: string; induction_done?: number }) {
    let url = `${environment.report_prefix_url}/getInductionReport?page_size=${payload.page_size}&current_page=${payload.current_page}&start_date=${payload.start_date}&end_date=${payload.end_date}`;
    if (payload.induction_done !== undefined) {
      url += `&induction_done=${payload.induction_done}`;
    }
    return this.http.get(url);
  }
  viewInductionReport(patient_id) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/provisional-diagnosis/${patient_id}`)
  }

  getRenewalList(payload) {
    return this.http.post(`${environment.report_prefix_url}/getDueDateRppPatient`, payload)
  }

  getRenewalListWeek(payload) {
    return this.http.post(`${environment.report_prefix_url}/get-patient-renewal-after-7-days`, payload)
  }

  getOpdExpressReport(payload) {
    return this.http.post(`${environment.report_prefix_url}/get-opd-express-form-report`, payload)
  }

  getInactivePatientList(payload) {
    return this.http.post(`${environment.report_prefix_url}/getInactivePatientListGrid`, payload)
  }

  // Patient Session Report
  getPatientSessionReport(payload) {
    return this.http.get(`${environment.beneficiary_registration_prefix_url}/rpp/getPatientSessionReport?page_size=${payload.page_size}&current_page=${payload.current_page}&date=${payload.date}`)
  }

  getPatientTeamReport(payload) {
    return this.http.post(`${environment.beneficiary_registration_prefix_url}/rpp/getPatientTeamReport`, payload)
  }

  createSupportQuery(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updateSupportTicketQuery`, payload);
  }

  getSupportGrid(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getSupportTicketListGrid`, payload);
  }

  updateSupportTicketStatus(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updateSupportTicketStatus`, payload);
  }

  // Revenue Report
  getRevenueReport(payload) {
    return this.http.get(`${environment.report_prefix_direct_url}/revenue?page_size=${payload.page_size}&current_page=${payload.current_page}&start_date=${payload.start_date}&end_date=${payload.end_date}`)
  }

  // Compliance Report

  getComplianceReport(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getComplianceReport`, payload)
  }

  getRosterReports(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getAdminRosterReports`, payload)
  }

  getDetailedRoasterReport(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/adminRosterReportsData`, payload)
  }

  getHospitalLeadOpdRppStatus(payload: any) {
    return this.http.get(`${environment.report_prefix_url}/hospital-lead-opd-rpp-stats?year=${payload.year}&month=${payload.month}`);
  }

  // Summarised Report

  getSummarisedReport(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getComplianceReportSummarised`, payload)
  }

  getCouncellorPatientAllocation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientAllocation`, payload)
  }

  updatePatientAllocation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/updatePatientAllocation`, payload)
  }

  updateAllPatientAllocation(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/updateAllPatientAllocation`, payload)
  }

  // Audio Call Report

  getPatientAudioCallDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/ai/getPatientAudioCallDetails`, payload)
  }

  zoomRosterReportsForAdmin(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/zoomRosterReportsForAdmin`, payload)
  }

  syncTerraData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/terra/storeRawData`, payload, { responseType: 'text' })
      .pipe(
        map(response => ({ message: response }))
      );
  }

  fetchTerraData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/terra/wearableGraph`, payload)
  }

  getTerraCategoryDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/terra/table`, payload)
  }

  getTerraAbstractData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/terra/abstractTableData`, payload)
  }

  getUserRequestList(): Observable<PatientAppointmentItem[]> {
    return this.http.get<PatientAppointmentItem[]>(`${environment.patient_registration_prefix_url}/rpp/userRequestList`)
  }

  saveSessionSchedulePayload(payload: SessionPayload): Observable<any> {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/saveRequest`, payload);
  }

  saveSlotAssignment(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/saveRppMapping`, payload)
  }

  getWearableTwoData(payload) {
    return this.http.get(`${environment.report_prefix_url}/wearables-users/${payload.type}${payload.queryString}`)
  }

  getWearableTwoDataCounts() {
    return this.http.get(`${environment.report_prefix_url}/wearables-users/counts`)
  }

  getPatientAssessments(payload) {
    return this.http.get(`${environment.patient_assessment_prefix_url}/getPractitionerClientDetailsGrid?user_id=${payload}&practitioner_id=111111`)
  }

  getWearableAbnormalStats() {
    return this.http.get(`${environment.report_prefix_url}/wearables-users-stats`)
  }

  getPatientLifecycle(id: string) {
    return this.http.get(`${environment.report_prefix_url}/patient-lifecycle/${id}`)
  }

  getPatientMasterData(payload) {
    const current_page = payload.current_page ? `&current_page=${payload.current_page}` : '';
    const page_size = payload.page_size ? `&page_size=${payload.page_size}` : '';
    const start_date = payload.start_date ? `&start_date=${payload.start_date}` : '';
    const end_date = payload.end_date ? `&end_date=${payload.end_date}` : '';
    const lead_source = payload.lead_source ? `&lead_source=${payload.lead_source}` : '';
    const is_nvf_facility = payload.is_nvf_facility ? `&is_nvf_facility=${payload.is_nvf_facility}` : '';
    return this.http.get(`${environment.report_prefix_url}/patient-managment?${current_page}${page_size}${start_date}${end_date}${lead_source}${is_nvf_facility}`)
  }

  getMasterPatientRppData(patientId) {
    return this.http.get(`${environment.report_prefix_url}/rpp/patient/${patientId}`)
  }

  getMasterPatientPatientOpdData(patientId) {
    return this.http.get(`${environment.report_prefix_url}/opd/patient/${patientId}`)
  }

  getPatientNvfData(payload) {
    const current_page = payload.current_page ? `&current_page=${payload.current_page}` : '';
    const page_size = payload.page_size ? `&page_size=${payload.page_size}` : '';
    const start_date = payload.start_date ? `&start_date=${payload.start_date}` : '';
    const end_date = payload.end_date ? `&end_date=${payload.end_date}` : '';
    // const lead_source = payload.lead_source ? `&lead_source=${payload.lead_source}` : '';
    // const is_nvf_facility = payload.is_nvf_facility ? `&is_nvf_facility=${payload.is_nvf_facility}` : '';
    return this.http.get(`${environment.report_prefix_url}/patient/nvf?${current_page}${page_size}${start_date}${end_date}`)
  }

  getNvfStats(type: string, queryString: string) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/stats/${type}?${queryString}`)
  }

  getStageWiseDataCount(type: string, queryString: string) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/stage-wise/${type}?${queryString}`)
  }

  getAdditionalMetricsDataCount(type: string, queryString: string) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/additional-metrics/${type}?${queryString}`)
  }

  getDoctorWiseDataCount(type: string, queryString: string) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/doctor-wise/${type}?${queryString}`)
  }

  getTierWiseSummaryReport(payload) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/summary?start_date=${payload.start_date}&end_date=${payload.end_date}&section_name=${payload.section_name}&current_page=${payload.current_page}&page_size=${payload.page_size}`)
  }

  saveOfflineAssessment(payload) {
    return this.http.post(`${environment.patient_assessment_prefix_url}offline-assessment-catalogue`, payload)
  }

  getOfflineAssessmentCatalog() {
    return this.http.get(`${environment.patient_assessment_prefix_url}offline-assessment-catalogue`)
  }

  updateOfflineAssessment(payload) {
    return this.http.put(`${environment.patient_assessment_prefix_url}offline-assessment-catalogue`, payload)
  }

  deleteOfflineAssessment(id) {
    return this.http.delete(`${environment.patient_assessment_prefix_url}offline-assessment-catalogue/${id}`)
  }

  savePatientOfflineAssessment(payload) {
    return this.http.post(`${environment.patient_assessment_prefix_url}offline-assessment`, payload)
  }

  getPatientOfflineAssessment(payload) {
    return this.http.post(`${environment.patient_assessment_prefix_url}get-offline-assessment/`, payload)
  }

  getPatientOfflineAssessmentReport(payload) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${environment.patient_assessment_prefix_url}download/offline-assessment`, payload, { headers, responseType: 'blob' })
  }

  getDrillDownData(type: string, queryString: string) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/drildown/${type}?${queryString}`)
  }

  getNVFExcelData(queryString: string) {
    return this.http.get(`${environment.report_prefix_url}/patient/nvf/download/raw_data?${queryString}`)
  }

  getAllLeadSources() {
    return this.http.get(`${environment.admin_prefix_url}/leadsource/get-all-lead-sources`)
  }

  addLeadSource(payload) {
    return this.http.post(`${environment.admin_prefix_url}/leadsource/lead-source`, payload)
  }

  getAllLeadSource() {
    return this.http.get(`${environment.admin_prefix_url}/leadsource/get-all-lead-sources`)
  }

  deleteLeadSource(id) {
    return this.http.delete(`${environment.admin_prefix_url}/leadsource/lead-source/${id}`)
  }

  updateLeadSource(payload) {
    return this.http.put(`${environment.admin_prefix_url}/leadsource/lead-source`, payload)
  }

  getDiagnosisPackages() {
    return this.http.get(`${environment.admin_prefix_url}/package-diagnosis`)
  }

  getAssessmentCategories() {
    return this.http.get(`${environment.patient_assessment_prefix_url}categories`)
  }

  getAssessmentList(payload) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/assessment?page_size=${payload.page_size}&current_page=${payload.current_page}`)
  }

  getOisList(payload) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/ois?page_size=${payload.page_size}&current_page=${payload.current_page}`)
  }

}
