<!-- START : Page  -->
<div class="box">
  <div class="box-header pb-3 pt-3">
    <h4 class="box-title">
      <span class="">
        RPP Patient List</span>
    </h4>
  </div>
  <!-- User statistics  -->

  <div class="row">
    <div class="box-body">
      <div class="row">
        <div class="d-flex align-items-center justify-space-between gap-4">
          <div class="info-box-top-inner gradient-blue">
            <span class="info-box-icon-inner box_four rounded">
              <span class="icon-Equalizer"><i class="pi pi pi-users"></i></span>
            </span>
            <span class="info-box-text-inner fs ml-3" style="padding-left: 3%">
              Active Count
              <span class="info-box-tex ml-3 tpf cursor">{{ countObject.activeCount }}</span>
            </span>
          </div>
          <div class="info-box-top-inner gradient-blue">
            <span class="info-box-icon-inner box_four rounded">
              <span class="icon-Equalizer"><i class="pi pi-list"></i></span>
            </span>
            <span class="info-box-text-inner fs ml-3" style="padding-left: 3%">
              Total Count
              <span class="info-box-tex ml-3 tpf cursor">{{ countObject.totalRecord }}</span>
            </span>
          </div>
          <div class="info-box-top-inner gradient-blue">
            <span class="info-box-icon-inner box_four rounded">
              <span class="icon-Equalizer"><i class="pi pi-pause"></i></span>
            </span>
            <span class="info-box-text-inner fs ml-3" style="padding-left: 3%">
              Hold Count
              <span class="info-box-tex ml-3 tpf cursor">{{ countObject.holdCount }}</span>
            </span>
          </div>
          <div class="info-box-top-inner gradient-blue">
            <span class="info-box-icon-inner box_four rounded">
              <span class="icon-Equalizer"><i class="pi pi-ban"></i></span>
            </span>
            <span class="info-box-text-inner fs ml-3" style="padding-left: 3%">
              InActive Count
              <span class="info-box-tex ml-3 tpf cursor">{{ countObject.inActiveCount }}</span>
            </span>
          </div>

        </div>
        <div class="col-lg-4 col-md-4 col-sm-12"></div>

        <div class="col-md-8 ml-auto text-secondary">

          <div class="input-group mb-3 search-mob pull-right">
            <input type="text" class="form-control" name="contact" (focusout)="onFocusOutEvent($event)"
              (keydown.enter)="searchWithPhoneNumber()" [(ngModel)]="contactNumber" placeholder="Name/Mobile No.">
            <div class="input-group-append">
              <button [ngClass]="{'btn-dark' : disableSearch, 'btn-primary': !disableSearch}" class="btn btn-sm"
                type="button" (click)="searchWithPhoneNumber()"><i class="pi pi-search"></i></button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="row no-gutters grid-table-height">
            <div>
              <strong>{{noteinfo}}</strong>

            </div>
            <div class="col">
              <app-commongrid #dataGrid [colModel]='cols' gridId='rppPatientListGrid' sField='updatedDate'
                selectType='none' sOrder='desc' [localData]="[]" idColumn='patient_rpp_assignment_id' [rowsPerPage]='50'
                paginationpos='bottom' (onCustomPageAndFilterEvent)="onPageChangeEvent($event)"
                (onCustomFilterEvent)="onCustomFilter($event)">
                <!-- <ng-container #redirectUser let-gridData='griddata' > -->

                <ng-template #nvfStatus let-gridData='griddata'>
                  <span>{{ gridData.is_nvf_facility ? 'Yes': 'No' }}</span>
                </ng-template>

                <ng-template #redirectUser let-gridData='griddata'>
                  <a [routerLink]="['/rpp-patient-list/patient-dashboard']" *ngIf="gridData.patient_name"
                    (click)="onCustomrowSelect(gridData)"> {{ gridData.patient_name}}</a>
                </ng-template>
                <ng-template #actionTemplate let-gridData='griddata'>
                  <!-- <button *ngIf="gridData.service_name != 'Psychological Supervision'"
                                    (click)="editRppList(gridData)" class="butonclover" title="Service Allocation">
                                   <span class="actionicons iconscls text-warning"><i class="ti ti-link"></i></span>
                               </button> -->
                  <button mat-icon-button [matMenuTriggerFor]="menu" class="pi pi-ellipsis-v"></button>
                  <mat-menu #menu="matMenu">
                    <button class="ttttt" title="{{grid.service_name}}" alt="{{grid.service_name}}" mat-menu-item
                      *ngFor="let grid of gridData.service_details " (click)="editRppList(grid,gridData)">
                      {{grid.service_name}}
                    </button>
                  </mat-menu>

                  <!-- <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngFor="let grid of gridData.service_details " (click)="editRppList(grid,gridData)"> 
                                    {{grid.service_name}}
                                </button>
                                
                              </mat-menu> -->
                </ng-template>

                <ng-template #statuTemplate let-gridData='griddata'>
                  <ng-container *ngIf="gridData.status == 'Paid'">
                    <span class="badge badge-success">Paid</span>
                  </ng-container>
                  <ng-container *ngIf="gridData.status == 'Payment link Generated'">
                    <span class="badge badge-info">Payment link Generated</span>
                  </ng-container>
                  <ng-container *ngIf="gridData.status == 'Payment link Regenerated'">
                    <span class="badge badge-info">Payment link Regenerated</span>
                  </ng-container>
                  <ng-container *ngIf="gridData.status == 'Ordered'">
                    <span class="badge badge-success">Ordered</span>
                  </ng-container>
                  <ng-container *ngIf="gridData.status == 'Payment Failed'">
                    <span class="badge badge-danger">Payment Failed</span>
                  </ng-container>
                  <ng-container *ngIf="gridData.status == 'Payment link Expired'">
                    <span class="badge badge-danger">Payment link Expired</span>
                  </ng-container>
                  <ng-container *ngIf="gridData.status == 'Assigned'">
                    <span class="badge badge-inverse">Assigned</span>
                  </ng-container>

                </ng-template>
                <ng-container *ngFor="let row of rows">
                  <ng-container *ngTemplateOutlet="getTemplate(row); context: {$implicit: row}"></ng-container>
                </ng-container>
                <ng-template #carryOnButton let-row>
                  <button (click)="handleCarryOn(row)" class="custom-button carry-on-button">Carry On</button>
                </ng-template>

                <ng-template #onHoldButton let-row>
                  <button (click)="handleHold(row)" class="custom-button hold-button">Hold</button>
                </ng-template>

                <ng-template #timelineIcon1 let-gridData='griddata'>
                  <ng-container *ngIf="gridData.is_hold !== undefined">
                    <ng-container
                      *ngTemplateOutlet="getTemplate(gridData); context: {$implicit: gridData}"></ng-container>
                  </ng-container>
                </ng-template>

                <ng-template #timelineIcon let-gridData='griddata'>
                  <button pButton icon="pi pi-eye" (click)="onTimeLineSelect(gridData.patient_id, $event)"></button>
                </ng-template>
              </app-commongrid>
              <!--  -->

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- END : Page  -->