import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({providedIn: 'root'})
export class RPPPatientMappingService {
private baseUrl: 'https://dev-api-lb.emoneeds.com/api/v1/registration/rpp/';

  private consultationId = new BehaviorSubject<any>('');
  getConsultationId = this.consultationId.asObservable();

  constructor(private http: HttpClient) {}

  setConsultationId(data){
    this.consultationId.next(data); 
  }

  getRppSlots(payload) {
    return this.http.post(`${environment.admin_prefix_url}/doctor_roster/getRppSlots`, payload);
  }

  getPatientMappingData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientRppMappingGrid`, payload);
  }

  getPatientDashboardDetails(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/getPatientDashboardDetails`, payload);
  }

  getDashboardServicePermission(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getRppPermissions`, payload);
  }

  getPatientTodaySlotAssignStatus(rppId: string) {
    return this.http.get(`${environment.patient_registration_prefix_url}/rpp/getBookedSlots/${rppId}`)
  }

  addRppMappingData(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/addRppMapping`, payload);
  }

  addRppMappingTest(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/addRppMappingTest`, payload);
  }

  updateRppMapping(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/updateRppMapping`, payload);
  }

  getSlotsByDate(payload) {
    return this.http.post(`${environment.admin_prefix_url}/doctor_roster/getRppSlotsByDate`, payload);
  }
  holdPatient(rppId: string, payload) {
    // const url = `${this.baseUrl}/hold/${rppId}`;
    return this.http.put(`${environment.beneficiary_registration_prefix_url}/rpp/hold/${rppId}`, payload);
  }

  carryOnPatient(rppId: string): Observable<any> {
    const url = `${this.baseUrl}/carry-on/${rppId}`;
    return this.http.patch(url, {});
  }
}
