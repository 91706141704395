import { Component, Host, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Colmodel, GridFIlterType } from 'src/app/modules/commongrid/colmodel.model';
import { CommongridComponent } from 'src/app/modules/commongrid/commongrid.component';

import { DataService, UtilsService } from 'src/app/modules/shared/services';
import { AuthService } from '../auth/services/auth.service';

import { DialogService } from 'primeng/dynamicdialog';
import { PatientRegisterService } from '../patient-registration/services/patient-register.service';
import { RppPatientService } from '../rpp-patient-list/services/rpp-patient.service';
import { RPPPatientMappingService } from './services/rpp-patient-mapping.service'

@Component({
  selector: 'app-rpp-patient-allocate',
  templateUrl: './rpp-patient-allocate.component.html',
  styleUrls: ['./rpp-patient-allocate.component.scss']
})
export class RppPatientAllocateComponent implements OnInit {

  globalPayLoad;
  cols: Colmodel[] = [];
  currentPage = 1;
  disableSearch = true;
  disableColumn
  dataSource
  patinetname
  noteinfo
  gridData
  countObject: any = {};
  RowData: any[]
  public contactNumber: any = '';
  @ViewChild(CommongridComponent) dataGrid: CommongridComponent;
  @ViewChild('redirectUser', { static: true }) redirectUser;
  @ViewChild('actionTemplate', { static: true }) actionTemplate;
  @ViewChild('statuTemplate', { static: true }) statuTemplate;
  @ViewChild('timelineIcon', { static: true }) timelineIcon;
  @ViewChild('timelineIcon1', { static: true }) timelineIcon1;
  @ViewChild('nvfStatus', { static: true }) nvfStatus;
  @ViewChild('carryOnButton', { static: true }) carryOnButton: TemplateRef<any>;
  @ViewChild('onHoldButton', { static: true }) onHoldButton: TemplateRef<any>;
  rows = [
    { is_hold: true, /* other properties */ },
    { is_hold: false, /* other properties */ },
    // more rows
  ];
  getTemplate(row: { is_hold: boolean }): TemplateRef<any> {
    return row.is_hold ? this.carryOnButton : this.onHoldButton;
  }

  constructor(public dataService: DataService,
    private rppPatientService: RppPatientService,
    public authService: AuthService,
    public route: Router,
    public utilsService: UtilsService,
    private rppPatientMappingService: RPPPatientMappingService,

    private patientRegisterService: PatientRegisterService) {
    const navigation = this.route.getCurrentNavigation();
    const state = navigation.extras.state;
    console.log("saate is", state);

    if (!state) {
      this.route.navigate(['/rpp-patient-list']);
      return;
    }
    this.gridData = JSON.parse(state.editData);
    console.log("grid data is", this.gridData);

  }

  ngOnInit(): void {
    if (this.dataService.userDetails.name == 'Receptionist') {
      this.disableColumn = true;
    }
    else {
      this.disableColumn = false;
    }
    this.cols = [

      new Colmodel('serial_num', 'Sr. No', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_ref_id', 'Patient Id', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_id', 'Id', false, true, true, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_name', 'Patient Name', false, true, false, { filtertype: GridFIlterType.INPUT, formatterTemplateRef: this.redirectUser }),
      new Colmodel('mobile_number', 'Mobile Number', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('package_name', 'Plan Name', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('enrollment_date', 'Enrollment Date', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('due_date', 'Due Date', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('is_nvf_facility', 'NVF Status', false, true, false, { filtertype: GridFIlterType.INPUT, formatterTemplateRef: this.nvfStatus }),
      new Colmodel('slot_status', 'Service Status', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('assesment_status', 'Assessment Status', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('wearable_status', 'Wearable Status', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('wearable_last_comment', 'Wearable Last Comment', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('isConsentFormFilled', 'Consent Form Filled', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('date_consent_created', 'Consent Date', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('comments', 'Comments', false, true, false, { filtertype: GridFIlterType.INPUT }),

      // new Colmodel('payment_type_name', 'Mode of Payment', false, true, false, {filtertype: GridFIlterType.INPUT}),

      // new Colmodel('status', 'Status', false, true, false, {filtertype: GridFIlterType.NONE, formatterTemplateRef: this.statuTemplate}),

      new Colmodel('', 'Timeline', false, false, this.disableColumn, { filtertype: GridFIlterType.NONE, formatterTemplateRef: this.timelineIcon }),
      new Colmodel(
        'is_hold',
        'Is hold',
        false,
        false,
        this.disableColumn,
        { filtertype: GridFIlterType.NONE, formatterTemplateRef: this.timelineIcon1 }
      ),



      new Colmodel('action', 'Actions', false, false, this.disableColumn, { filtertype: GridFIlterType.NONE, formatterTemplateRef: this.actionTemplate }),
    ];

    this.getPatientGridData()
  }
  refreshPage() {
    this.getPatientGridData();  // Re-fetch the grid data to reflect the changes
  }

  handleHold(row: { patient_rpp_id: string, is_hold: boolean }) {
    const payload = {
      // Add any additional data that your API requires
      status: 'true',
      reason: 'Patient requested hold', // Example key-value pair
    };
    this.rppPatientMappingService.holdPatient(row.patient_rpp_id, payload).subscribe(response => {
      // Logic after successful API call
      row.is_hold = true; // Update the local data to reflect the change
      this.refreshPage();  // Refresh the page
    }, error => {
      console.error('Error while holding:', error);
    });
  }
  handleCarryOn(row: { id: string, is_hold: boolean }) {
    this.rppPatientMappingService.carryOnPatient(row.id).subscribe(response => {
      // Logic after successful API call
      row.is_hold = false; // Update the local data to reflect the change
      this.refreshPage();  // Refresh the page
    }, error => {
      console.error('Error while carrying on:', error);
    });
  }
  getPatientGridData() {
    let renewdata = false
    if (this.gridData == 'renew') {
      renewdata = true
      this.noteinfo = "Note: RPP Renewal due in next 7 days"
    }
    const tempPayload = {
      page_size: this.dataGrid && this.dataGrid.rows ? this.dataGrid.rows : 50,
      current_page: this.currentPage,
      renew_next_week: renewdata
    };
    this.rppPatientService
      .getPatientRPPMappingDetails(tempPayload)
      .subscribe((res: any) => {
        res.data.forEach((ele) => {
          let serviceName = []
          ele.service_details.forEach((element) => {
            serviceName.push(element.service_name)
          })
          ele.service_names = serviceName.join(' , ')
          ele.assesment_status = ele.assesment_status ? 'Yes' : 'No';
          ele.wearable_status = ele.wearable_status ? 'Yes' : 'No';
          this.countObject.totalRecord = res.count;
          this.countObject.activeCount = res.activeCount;
          this.countObject.holdCount = res.holdCount;
          this.countObject.inActiveCount = res.inActiveCount;
        })
        this.dataGrid.data = res.data;
        this.dataGrid.totalRecords = res.count;
      });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    this.disableSearch = this.contactNumber.length >= 9 ? false : true;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFocusOutEvent(event) {
    this.disableSearch = this.contactNumber.length >= 10 ? false : true;
    if (this.disableSearch) {
      this.getPatientGridData()
    }
  }

  searchWithPhoneNumber() {

    let renewdata = false
    if (this.gridData == 'renew') {
      renewdata = true
    }
    let payload =
    {
      renew_next_week: renewdata,
      registration_source: 2,
      search_keyword: this.contactNumber
    }
    // this.rppPatientService.findbyMobileNumber(payload).subscribe((res: any) => {
    //   this.dataGrid.data = res.data;
    //   this.dataGrid.totalRecords = res.count;
    // })
    this.rppPatientService
      .findbyMobileNumber(payload)
      .subscribe((res: any) => {
        res.data.forEach((ele, index) => {
          let serviceName = []
          ele.service_details.forEach((element) => {
            serviceName.push(element.service_name)
          })
          ele.service_names = serviceName.join(' , ')

          let srnumbers = index + 1
          ele.serial_num = srnumbers;

        })

        // res.data.forEach((data: any, index) => {
        //   let srnumbers = index + 1
        //   data.serial_num = srnumbers;
        // });

        this.dataGrid.data = res.data;
        this.dataGrid.totalRecords = res.count;
      });
  }

  editRppList(grid, gridDetail) {
    // debugger
    const navigationExtras: NavigationExtras = {
      state: {
        editData: JSON.stringify(grid),
        gridData: JSON.stringify(gridDetail),
        grid: JSON.stringify(grid),
        currentPage: this.currentPage,
      },

    };

    if (grid.service_type == 1) {
      this.route.navigate([`/rpp-patient-list/individual-service/${gridDetail.patient_id}`], navigationExtras)
    } else {
      this.route.navigate([`/rpp-patient-list/group-service/${gridDetail.patient_id}`], navigationExtras)
    }
    // if(grid.service_name == 'Psychodiagnostic Evaluation (Psychiatrist)' ||
    // grid.service_name == 'Psychodiagnostic Evaluation (Psychologist)'){
    //   this.route.navigate([`/patientregistration/edit-patient/${gridDetail.patient_id}`])
    // }

    // if(grid.service_name == 'Daily Activities'){
    //   this.route.navigate([`/patient-dashboard/form/${gridDetail.patient_id}/add-activity-feedback`])
    // }

    // if(grid.service_name == 'Psychologist Consultation'){
    //   this.route.navigate([`/patient-dashboard/form/${gridDetail.patient_id}/add-session-psychologist`])
    // }
    // if(grid.service_name == 'Psychiatrist Consultation'){
    //   this.route.navigate([`/patient-dashboard/form/${gridDetail.patient_id}/add-session-psychiatrist`])
    // }
    // if(grid.service_name == 'Psychological Supervision'){
    //   this.route.navigate([`/patient-dashboard/form/${gridDetail.patient_id}/add-daily-feedback`])
    // }





  }

  onCustomrowSelect(event) {
    const navigationExtras: NavigationExtras = {
      state: { editData: JSON.stringify(event) },
    };
    this.route.navigate([`/rpp-patient-list/patient-dashboard/${event.patient_id}`], navigationExtras)
  }

  onTimeLineSelect(patientId, event) {
    event.stopPropagation();
    this.route.navigate([`/rpp-patient-list/timeline/${patientId}`])
  }

  onCustomFilter(event) {
    this.dataGrid.totalRecords = event.data.length;
  }

  onPageChangeEvent(event) {
    this.currentPage = event.first == 0 ? 1 : (event.first / event.rows) + 1;
    this.dataGrid.rows = event.rows;
    // const payload = {filters: event.filters};
    const payload = { limit: true };
    this.getPatientGridData();
  }
}
